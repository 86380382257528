<template>
  <div @click="$emit('click')"
       class="d-flex v-list-item--dense align-center" style="position: relative;">
    <record-list-item-avatar-icon :record="record"/>
    <record-list-item-avatar-img :record="record" class="mr-4"/>
    <v-badge
        dot
        color="success"
        top offset-x="25" offset-y="-5"
        :value="record.meta.modified"
        class="ee09-admin-blink"
    />

    <v-badge
        :value="record.isPage && !record.isPublished"
        color="orange"
        bottom offset-x="60" offset-y="21"
        :icon="record.notPublishedIcon"
    />
    <v-badge
        :value="false"
        color="primary"
        bottom offset-x="60" offset-y="-1"
        :icon="$icons.clock"
    />

    <record-list-item-content :record="record">
      <template slot="more">
        <slot name="more"></slot>
      </template>
    </record-list-item-content>
    <record-list-item-action :record="record">
      <template slot="action">
        <slot name="action"></slot>
      </template>
    </record-list-item-action>
  </div>
</template>

<script>
    import RecordListItemContent from "./list-item/record-list-item-content";
    import RecordListItemAction from "./list-item/record-list-item-action";
    import RecordListItemAvatarIcon from "@/ee09/db-ui/records/list-item/record-list-item-avatar-icon";
    import RecordListItemAvatarImg from "@/ee09/db-ui/records/list-item/record-list-item-avatar-img";
    export default {
        name: "record-list-item-inner-div",
        components: {
          RecordListItemAvatarImg,
          RecordListItemAvatarIcon,
          RecordListItemAction, RecordListItemContent},
        props: {
            /**
             *  @type {DbRecord}
             */
            "record":{type:Object},
            /**
             * Permet d'ajouter un lien ver la page d'édition
             */
            link:{type:Boolean,default:false}
        },
    }
</script>
