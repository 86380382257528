<template>
    <label class="caption theme--light v-label d-block">
        <slot></slot>
    </label>
</template>

<script>
    export default {
        name: "field-label"
    }
</script>