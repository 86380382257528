export default {
    props:{
        label:{type:String},
        placeholder:{type:String},
        field:{type:Object},
        recordTypes:{type:Array},
    },
    methods:{
        /**
         * Filtre de recherche dans les records
         * @param item
         * @param queryText
         * @return {boolean}
         */
        customFilter (item, queryText) {
            const textOne = item.name.toLowerCase()
            const searchText = queryText.toLowerCase()
            return textOne.indexOf(searchText) > -1;
        }
    },
    computed:{
        /**
         * La liste des records dans l'autosuggest
         * @return {DbRecord[]}
         */
        possibleRecords(){
            let r=[];
            if(this.recordTypes.length===0){
                r = this.$db.recordListPage;
            }else{
                for(let type of this.recordTypes){
                    r=r.concat(r,window.$db.getListType(type))
                }
            }
            r=this.$db.utils.records.sortByName(r);
            return r;
        }
    }
}