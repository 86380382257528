<template>
<div class="record-field mb-4">
  <v-system-bar lights-out>
  <field-label
      v-if="label"
  >{{label}}</field-label>
  </v-system-bar>
  <v-autocomplete
      v-model="f.record"
      :placeholder="placeholder"
      :items="possibleRecords"
      :filter="customFilter"
      filled
      item-text="name"
      return-object
      hide-details
  >
    <template v-slot:prepend-inner>
      <!-- permet par exemple de placer un drapeau ;) -->
      <slot name="prepend"></slot>
    </template>

    <template v-slot:append>
      <slot name="action"></slot>
    </template>

    <!-- le record sélectionné -->
    <template v-slot:selection="data">
      <record-list-item :record="data.item" class="ml-n3"/>
    </template>

    <!-- Liste des fichiers -->
    <template v-slot:item="{item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs">
        <record-list-item-inner-div :record="item"/>
      </v-list-item>
    </template>

  </v-autocomplete>

</div>
</template>

<script>
import RecordListItem from "../records/record-list-item";
import FieldLabel from "./field-label";
import RecordFieldMixin from "@/ee09/db-ui/fields/RecordFieldMixin";
import RecordListItemInner from "@/ee09/db-ui/records/record-list-item-inner";
import RecordListItemInnerDiv from "@/ee09/db-ui/records/record-list-item-inner-div";
export default {
  name: "record-field",
  mixins:[RecordFieldMixin],
  components: {
    RecordListItemInnerDiv,
    // eslint-disable-next-line vue/no-unused-components
    RecordListItemInner,
    FieldLabel,
    RecordListItem,
  },
  data(){return{}},
  computed:{
    /**
     * Le champ record
     * @return {RecordField}
     */
    f(){
      return this.field;
    },
  }
}
</script>
